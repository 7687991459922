<template>
  <div>
    <b-card>
      <cursosList />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import cursosList from './components/CursosList.vue'

export default {
  components: {
    BCard,
    cursosList,
  },
  created() {
    // Consultar autenticación del
  },
}
</script>

<style>

</style>
