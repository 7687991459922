<template>
  <b-row>
    <b-col
      lg="2"
      md="3"
      sm="3"
      class="my-1"
    >
      <!-- BOTON MOSTRAR -->
      <btnMostrar
        :pageOptions.sync="pageOptions"
        :perPage.sync="perPage"
        :total.sync="getCursos.length"
      />
    </b-col>
    <b-col
      lg="1"
      md="1"
      sm="1"
      class="my-1"
    >
    </b-col>
    <b-col
      lg="6"
      md="5"
      sm="5"
      class="my-1"
    >
      <!-- FILTRO -->
      <inputFiltro
        :filter.sync="filter"
      />
    </b-col>
    <b-col
      md="3"
      sm="3"
      class="my-1"
    >
      <!-- BOTON CREAR -->
      <btnCrear
        texto="Curso"
        modulo="cursos"
        @processAdd="addCurso"
      />
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="getCursos"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template> -->

        <!-- COLUMNA ESTADO -->
        <template #cell(nombreGrado)="data">
          {{ data.item.nombreGrado }} {{ data.item.letra }}
        </template>

        <!-- Column: Action -->
        <template #cell(acciones)="data">
          <colAccionesBtnes
            modulo="cursos"
            :data="data"
            @processGoToUpdate="goToUpdate"
            @processGoToOrdenarLista="goToOrdenarLista"
            @processRemove="remove(data.item)"
          />
          <!-- :estado="data.item.destroy.estado"
          :cantidad="data.item.destroy.cantidad" -->
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import colAccionesBtnes from '../../components/List/colAccionesBtnes.vue'
import btnCrear from '../../components/List/btnCrear.vue'
import inputFiltro from '../../components/List/inputFiltro.vue'
import btnMostrar from '../../components/List/btnMostrar.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,

    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
  },
  data() {
    return {
      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'nombreGrado',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreProfesorJefe',
          label: 'Profesor Guía',
          sortable: false,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreEstablecimiento',
          label: 'Establecimiento',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getCursos: 'cursos/getCursos' }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    getCursos(val) {
      this.totalRows = val.length
    },
  },
  mounted() {
    this.fetchCursos()
    this.setTableList()
  },
  methods: {
    ...mapActions({
      fetchCursos: 'cursos/fetchCursos',
      updateCursoEstado: 'cursos/updateCursoEstado',
      removeCursos: 'cursos/removeCursos',
    }),
    ...mapMutations('cursos', ['setCurso']),
    setTableList() {
      if (this.$can('update', 'establecimientos')
        || this.$can('delete', 'establecimientos')
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    addCurso() {
      this.$router.replace({
        name: 'cursos-create',
      })
    },
    updateEstado(curso) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${curso.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, cambialo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateCursoEstado(curso)
          this.$swal({
            icon: 'success',
            title: 'Estado actualizado!',
            text: `Se ha actualizado el estado de "${curso.nombre}"!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    goToOrdenarLista(curso) {
      this.setCurso(curso)
      this.$router.push({
        name: 'ordenar-lista',
        params: { id: curso.id },
      })
    },
    goToUpdate(curso) {
      this.setCurso(curso)
      this.$router.push({
        name: 'cursos-update',
        params: { id: curso.id },
      })
    },
    remove(curso) {
      this.$swal({
        title: 'Eliminar curso!',
        text: `Estás seguro que deseas eliminar la curso "${curso.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeCursos(curso.id)
          this.$swal({
            icon: 'success',
            title: 'Eliminada con éxito!',
            text: `"${curso.nombre}" ha sido eliminada!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
